import React, {ReactNode} from "react";
import NavBar from "./Components/Sidebar/NavBar";
import HeaderTabs from "./Components/Header/HeaderTabs";

export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: ReactNode;
}

const MedicareLayout = ({children}: LayoutProps) => {
    return (
        <div className="nav_wrapper">
            <NavBar />
            <HeaderTabs />
            <div className="layout-wrapper" id="main-panel">
                {children}
            </div>
        </div>
    );
};

export default MedicareLayout;
