import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {McConfig} from "../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

/** Nature of the dashboard means that defining this once is fine as routes will be the same */
export function getNavItemsForUser(user: UserData, config: McConfig): NavigationItem[] {
    const accessLevel = getUserAccessLevel(user, config);
    switch (accessLevel) {
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: routeNames.dashboard.name,
                    path: routeNames.dashboard.path
                }
                //                getTotpRoute(user.totp || false)
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.dashboard.name,
                    path: routeNames.dashboard.path
                },
                {
                    name: routeNames.wageCalculation.name,
                    path: routeNames.wageCalculation.path
                }
                //                getTotpRoute(user.totp || false)
            ];
    }
}

//function getTotpRoute(isTotp: boolean): NavigationItem {
//    const routeName = isTotp ? "Register new authenticator" : "Register authenticator";

//    return {
//        name: routeName,
//       path: routeNames.totp.path
//    };
//}
