import styles from "./NavBar.module.css";
import {FaBriefcase, FaFolderOpen} from "react-icons/fa";
import {FaUserGroup} from "react-icons/fa6";
import {PiAmbulanceFill} from "react-icons/pi";
import {HiClipboardDocumentList, HiMiniClipboardDocumentCheck} from "react-icons/hi2";
import {GiMedicinePills} from "react-icons/gi";
import {TbLink, TbTrashXFilled, TbX} from "react-icons/tb";
import {RiSkullFill} from "react-icons/ri";
import React, {ReactNode, useCallback, useEffect, useRef} from "react";
import CompanyLogo from "./CompanyLogo";
import CompanyLogoSmallSquare from "./CompanyLogoSmallSquare";
import {IoCalendarNumberSharp, IoDocumentTextSharp, IoLockClosed} from "react-icons/io5";
import {IoMdLogOut} from "react-icons/io";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {useUserData} from "../../../../../Hooks/useUserData";
import {setSidebarToggled} from "../../../../../../store/sidebar/actions/SidebarActions";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {BsExclamationSquareFill} from "react-icons/bs";
import {Link} from "react-router-dom";

function NavHeading({heading}: {heading: string}) {
    return <h1 className={styles.heading}>{heading}</h1>;
}

function PlainIcon({icon, title}: {icon: ReactNode; title: string}) {
    return (
        <>
            {icon}
            <div className={styles.block_head}>{title}</div>
        </>
    );
}

function NavMainItem({
    icon,
    title,
    url,
    children,
    restrictLevels
}: {
    icon: ReactNode;
    title: string;
    url?: string;
    children?: ReactNode;
    restrictLevels?: StaffAccessLevel[];
}) {
    const user = useUserData();
    if (restrictLevels && restrictLevels.length > 0) {
        if (!user) return null;
        if (!restrictLevels.includes(user.accessLevel)) return null;
    }

    if (!url) {
        return (
            <div className={`${styles.block}`}>
                <div className={styles.content}>
                    <PlainIcon icon={icon} title={title} />
                    {children && <div className={styles.extended_content}>{children}</div>}
                </div>
            </div>
        );
    }

    if (url.startsWith("http")) {
        return (
            <a className={`${styles.block}`} href={url}>
                <div className={styles.content}>
                    <PlainIcon icon={icon} title={title} />
                    {children && <div className={styles.extended_content}>{children}</div>}
                </div>
            </a>
        );
    } else {
        return (
            <Link className={`${styles.block}`} to={url}>
                <div className={styles.content}>
                    <PlainIcon icon={icon} title={title} />
                    {children && <div className={styles.extended_content}>{children}</div>}
                </div>
            </Link>
        );
    }
}

function NavSection({
    title,
    children,
    className
}: {
    title: string;
    children: ReactNode;
    className?: string;
}) {
    const combinedClass = `${styles.section} ${className || ""}`;

    return (
        <div
            className={combinedClass}
            onTouchEnd={(e) => {
                e.stopPropagation();
            }}
        >
            <NavHeading heading={title} />

            <div className={styles.section_children}>{children}</div>
        </div>
    );
}

export default function NavBar() {
    const sidebarOpen = useSelector((state: RootStore) => state.sidebar.data) || false;
    const dispatch = useDispatch();
    const closeSidebar = useCallback(() => {
        dispatch(setSidebarToggled(false));
    }, [dispatch]);
    const showSidebar = useCallback(() => {
        dispatch(setSidebarToggled(true));
    }, [dispatch]);
    const navRef = useRef<HTMLDivElement>(null);
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];

    // Bind nav hover show/hide
    useEffect(() => {
        const elem = navRef.current;
        if (!elem) return;

        const onHover = () => showSidebar();
        const onLeave = () => closeSidebar();

        elem.addEventListener("mouseenter", onHover);
        elem.addEventListener("mouseleave", onLeave);

        return () => {
            elem.removeEventListener("mouseenter", onHover);
            elem.removeEventListener("mouseleave", onLeave);
        };
    }, [closeSidebar, navRef, showSidebar]);

    return (
        <div className={styles.navbar_wrapper}>
            <nav
                ref={navRef}
                className={`${styles.navbar} ${sidebarOpen ? styles.show : ""}`}
                onTouchEnd={() => dispatch(setSidebarToggled(!sidebarOpen))}
            >
                <div className={styles.logo_block}>
                    <a
                        className={styles.logo_block_inner}
                        href="https://dashboard.medicare-ems.co.uk"
                    >
                        <CompanyLogo className={styles.logo_big} />
                        <CompanyLogoSmallSquare className={styles.logo_small} />
                    </a>
                    <button
                        className={styles.close}
                        aria-label="Close navigation menu"
                        type="button"
                        onClick={() => closeSidebar()}
                    >
                        <TbX size={30} />
                    </button>
                </div>

                <NavSection title="Staff" className={styles.section_mobile}>
                    {navItems.map((item) => {
                        if (item === undefined || item.path === undefined) return null;

                        return (
                            <NavMainItem
                                key={item.path}
                                icon={<TbLink className={styles.block_img} />}
                                title={item.name}
                                url={item.path}
                            ></NavMainItem>
                        );
                    })}
                </NavSection>

                <NavSection title="Operations">
                    <NavMainItem
                        icon={<FaFolderOpen className={styles.block_img} />}
                        title="X-Drive"
                        url="https://xd2.medicare-ems.co.uk/x-drive"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<FaUserGroup className={styles.block_img} />}
                        title="Staff Management"
                        url="https://staffmanagement.medicare-ems.co.uk"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<IoCalendarNumberSharp className={styles.block_img} />}
                        title="Group Rostering System"
                        url="https://grs.medicare-ems.co.uk"
                    ></NavMainItem>
                    <NavMainItem
                        icon={<PiAmbulanceFill className={styles.block_img} />}
                        title="Fleet Management"
                        url="https://fms.medicare-ems.co.uk"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<FaBriefcase className={styles.block_img} />}
                        title="Leave Management"
                        url="https://absence.medicare-ems.co.uk"
                    ></NavMainItem>
                    <NavMainItem
                        icon={<HiClipboardDocumentList className={styles.block_img} />}
                        title="Operational Logs"
                        url="https://oplog.medicare-ems.co.uk"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                </NavSection>

                <NavSection title="Compliance">
                    <NavMainItem
                        icon={<HiMiniClipboardDocumentCheck className={styles.block_img} />}
                        title="PRF Audits"
                        url="https://prf.medicare-ems.co.uk"
                    ></NavMainItem>
                    <NavMainItem
                        icon={<TbTrashXFilled className={styles.block_img} />}
                        title="Clinical Waste Audit"
                        url="https://cwa.medicare-ems.co.uk"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<GiMedicinePills className={styles.block_img} />}
                        title="Medicine Management"
                        url="https://mm.medicare-ems.co.uk"
                        restrictLevels={[
                            StaffAccessLevel.SystemAdministrator,
                            StaffAccessLevel.DutyManager
                        ]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<IoDocumentTextSharp className={styles.block_img} />}
                        title="My Documents"
                        url="https://ppb.medicare-ems.co.uk"
                    ></NavMainItem>
                    <NavMainItem
                        icon={<RiSkullFill className={styles.block_img} />}
                        title="Risk Management"
                        url="https://rm.medicare-ems.co.uk"
                        restrictLevels={[StaffAccessLevel.SystemAdministrator]}
                    ></NavMainItem>
                    <NavMainItem
                        icon={<BsExclamationSquareFill className={styles.block_img} />}
                        title="Incident Management"
                        url="https://im.medicare-ems.co.uk"
                    ></NavMainItem>
                </NavSection>

                <NavSection title="Account">
                    <NavMainItem
                        icon={<IoLockClosed className={styles.block_img} />}
                        title="Register Authenticator"
                        url="/totp"
                    ></NavMainItem>
                    <NavMainItem
                        icon={<IoMdLogOut className={styles.block_img} />}
                        title="Logout"
                        url="/logout"
                    ></NavMainItem>
                </NavSection>
            </nav>
        </div>
    );
}
